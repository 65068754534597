<template>
  <div class="background">
    <div class="left_box">
    <div class="loginForm">
        <div class="formTitle">
          <div class="passwordLogin" @click="getQRCode">
            <p>扫码登陆</p>
            <span></span>
          <i></i>
          </div>
          <div class="passwordLoginForm" @click="loginType = 'passwordLogin'">
            <p>密码登陆</p>
            <span></span>
            <i></i>
          </div>
          <div class="codeLogin" @click="loginType = 'codeLogin'">验证码登陆</div>
        </div>

        <div class="form">
          <div class="passwordLoginForm">
            <p class="title">朗云欢迎您</p>
            <p class="secTitle"  v-show="loginType !== 'QRLogin'">通过填写表格登陆您的账户</p>
            <!--密码登录-->
            <div class="inputArea" v-show="loginType === 'passwordLogin'">
              <p>手机号/邮箱</p>
              <input type="text" v-model="loginForm.text">
              <p>密码</p>
              <div style="position:relative">
                <input :type="inputPsdType" v-model="loginForm.password">
                <div class="eye" @click="changeInputType">
                  <i style="font-size:.28rem;color: #bebebe;margin-bottom: 1.35rem" class="fa">&#xf06e</i>
                </div>
              </div>
            </div>
            <!--验证码登录-->
            <div class="inputArea" v-show="loginType === 'codeLogin'">
              <p>手机号/邮箱</p>
              <input type="text" v-model="loginForm.text">
              <p>验证码</p>
              <div>
                <input type="text" v-model="loginForm.code" style=" height: 30px;
                      width: 100%;
                      margin-bottom: 31px;
                      border-bottom: 2px solid #d4d4d4;
                      margin-top: 24px;
                      float: left;">
                <p @click="sendCode"  style="float: right; color: #ec414d;margin-top: -72px; margin-left: 20px;cursor: pointer">发送验证码</p>
              </div>
            </div>
            <!--二维码登录-->
            <div class="QRLogin" v-show="loginType === 'QRLogin'">
              <div style="height: 250px;padding-top: 50px" >
                <!--等待扫码-->
                <el-result icon="info"
                           title="请扫码"
                           v-show="QRState === 'waiting'">
                  <template #sub-title>
                    <p>请扫描弹出窗口中的二维码</p>
                  </template>
                </el-result>
                <!--扫码成功-->
                <el-result
                    icon="success"
                    title="扫码成功"
                    sub-title="正在跳转，请稍后"
                    v-show="QRState === 'success'"
                >
                </el-result>
                <!--用户未绑定微信-->
                <el-result
                    icon="error"
                    title="用户不存在"
                    sub-title="请先登陆后再绑定微信"
                    v-show="QRState === 'error'"
                >
                </el-result>
              </div>
            </div>
            <!--注册引导-->
            <div class="signup">
              <p>没有账号?</p>
              <p @click="this.$router.push('/SignUp');">免费注册</p>
              <button @click="login" v-show="loginType !== 'QRLogin'">登陆</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightPic"></div>

  </div>
</template>

<script>
import {h, ref} from "vue";
import {ElDivider, ElMessage} from "element-plus";


export default {

  name: "Login",
  data(){
    return{
      spacer:h(ElDivider, { direction: 'vertical' }),

      loginType:'passwordLogin',

      QRCode:'',
      QRCodeKey:'',
      QRState:'waiting',
      QRCheckForm:{
        key:'',
      },
      codeState:0,
      QRCallBackForm:{},
      QRCheckUserStateForm:{
        openid:''
      },
      QRLoginForm:{
        type:'',
        role:'',
        userInfo:{},
      },

      loginForm:{
        text:'',
        password:'',
        code:'',
      },

      urlType:'',

      rememberMe:ref(['']),
      inputPsdType:'password',


    }
  },
  created() {
    if (window.sessionStorage.getItem("login") === "1"){
      this.$router.push('/Home');
    }
  },
  mounted() {

  },
  methods: {
    login() {
      if (this.loginType === "passwordLogin"){
        this.urlType = '/login';
      }else if(this.loginType === "codeLogin"){
        this.urlType = '/loginByCode';
      }
      this.$http({
        method: 'post',
        url: this.urlType,
        data: this.loginForm
      }).then(res => {
        if (res.data.code === 0) {
          this.$notify({
            title: '登陆失败',
            message: res.data.message,
            type: 'warning'
          });
        }else {
          this.messageName = res.data.user.username;
          //登陆提示
          this.$notify({
            title: '登陆成功',
            message: '你好，' + this.messageName,
            type: 'success'
          });
          window.sessionStorage.setItem("username",res.data.user.username);
          window.sessionStorage.setItem("token", res.data.token);
          window.sessionStorage.setItem("login", "1");
          window.sessionStorage.setItem("role", res.data.role);
          window.sessionStorage.setItem("UserId", res.data.user.id.toString());
          location.reload();
        }
      })
    },

    //密码显示切换
    changeInputType(){
      if (this.inputPsdType === 'password'){
        this.inputPsdType = 'text';
      }else {
        this.inputPsdType = 'password';
      }
    },

    sendCode(){
      this.$http({
        method: 'post',
        url: '/sendLoginCode',
        data: this.loginForm
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '发送成功',
            message: '',
            type: 'success'
          });
        }else {
          this.$notify({
            title: '发送失败',
            message: res.data.message,
            type: 'warning'
          });
        }

      })
    },

    getQRCode(){
      this.loginType = 'QRLogin';
      window.sessionStorage.clear()
      window.sessionStorage.setItem('login','0');
      window.clearInterval(this.timer)

      this.$http({
        method: 'post',
        url: '/wx/getWechatQtCode',
      }).then(res => {

        this.QRCode = res.data.url;
        this.QRCodeKey = res.data.key;
        this.QRCheckForm.key = this.QRCodeKey;
        //打开二维码窗口
        window.open(this.QRCode, "_blank", "top=300,left=300px,resizable,scrollbars,status,width=300,height=400,menubar=no,toolbar=no, status=no,scrollbars=no");

        this.timer = window.setInterval(() => {
          setTimeout(() => {
            this.checkQRState();
            if (this.QRCallBackForm.state === 2){
              window.clearInterval(this.timer)
              this.QRCheckUserStateForm.openid = this.QRCallBackForm.userInfo.openid;
              this.QRCheckUserState();
            }
          },0)
        },1000)
      })
    },

    checkQRState(){
      this.$http({
        method: 'post',
        url: '/wx/wxLogin/checkState',
        data:this.QRCheckForm
      }).then(res => {
        this.QRCallBackForm =res.data;
      })
    },

    //判断是否为新用户
    QRCheckUserState(){
      this.$http({
        method: 'post',
        url: '/wx/isExistForScanCodeUser',
        data:this.QRCheckUserStateForm
      }).then(res => {
          console.log(res);
          if (res.data.res === 0){
              this.QRState = 'error'
              return;
          }else if (res.data.res === 1){
              this.QRLogin();
          }
      })
    },

    QRLogin(){
      this.QRState = 'success';
      this.$http({
        method: 'post',
        url: '/wx/wxLogin',
        data:this.QRLoginForm
      }).then(res => {

        window.sessionStorage.setItem("token", res.data.token);
        window.sessionStorage.setItem("login", "1");
        window.sessionStorage.setItem("role", res.data.role);
      })
    },
  },
  components:{

  }
}


</script>

<style scoped>
.background{
  height: 934px;
  width: 1920px;

}


.left_box{
  position: absolute;
  width: 960px;
  height: 934px;
  background-image: url("../../images/login_bac1.jpg");
  background-size: 100% 100%;
}

.loginForm{
  position:absolute;
  top: 144px;
  left: 180px;
  width: 656px;
  height: 660px;
  box-shadow: #d4d2d2 0 15px 20px;
  -webkit-box-shadow: #d4d2d2 0 0 20px;
  -moz-box-shadow: #d4d2d2 0 0 20px;
  padding-left: 50px;
  padding-right: 51px;
  padding-top: 55px;
  background-color: #ffffff;
  border-radius: 10px;
}

.loginForm .eye{
  position: absolute;
  right: 0px;
  top: 15px;
  cursor: pointer;
}

.loginForm .formTitle{
  width: 556px;
  height: 52px;
  margin: 0 auto;
  border-bottom: 1px solid #979797;
  padding-left: 10px;
}


.loginForm .formTitle div{
  width: 174px;
  height: 52px;
  float: left;
  text-align: center;
  font-size: 24px;
  color: #212121;
  font-weight: 500;
  cursor: pointer;
}

.loginForm .formTitle .passwordLoginForm{
  width: 180px;
}

.loginForm .formTitle .passwordLoginForm p{
  float: left;
  width: 179px;
  height: 52px;
  margin-bottom: 26px;
}

.loginForm .formTitle .passwordLoginForm i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .passwordLogin p{
  float: left;
  width: 173px;
  height: 52px;
  margin-bottom: 26px;

}

.loginForm .formTitle .passwordLogin i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .codeLogin{
  margin-bottom: 26px;
  /*margin-left: 45px;*/
  padding-left: 20px;

}

.loginForm .form{
  font-size: 21px;
  font-weight: 350;
  color: rgba(60, 60, 60, 0.55);
}

.loginForm .form .passwordLoginForm  .title{
  font-size: 36px;
  font-weight: 300;
  color: #212121;
  margin-top: 50px;
}

.loginForm .form .passwordLoginForm  .secTitle{
  font-size: 18px;
  font-weight: 300;
  margin-top: 22px;
  margin-bottom: 28px;
  color: #3c3c3c;
}

.form .passwordLoginForm .input_area p{
  height: 21px;
}

.form .passwordLoginForm input{
  height: 30px;
  width: 100%;
  margin-bottom: 31px;
  border-bottom: 2px solid #d4d4d4;
  margin-top: 24px;

}

.form .passwordLoginForm .signup{
  margin-top: 38px;
  font-size: 18px;
  font-weight: 400;
}

.form .passwordLoginForm .signup p{
  float: left;
  width: 110px;
  height: 48px;
  display: table-cell;
}

.form .passwordLoginForm .signup p:nth-child(2){
  margin-left: 18px;
  color: rgb(235,66,77);
  cursor: pointer;
}

.form .passwordLoginForm button{
  float: right;
  background-color: rgb(235,66,77);
  font-size: 21px;
  width: 138px;
  height: 48px;
  left: 51px;
  line-height: 48px;
  border-radius: 5px;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}

.form .passwordLoginForm button:hover{
  background-size: 300%;
}

.rightPic{
  position:absolute;
  right:0;
  width:960px;
  height:934px;
  background-image: url("../../images/login_bac2.jpg");
  background-size: 100% 100%;
}

/* 组件样式css更改 */
::v-deep .el-checkbox_lable{
  font-size: 14px!important;
}


</style>
